<template>
<div class="footer fixed-bottom">
  <!-- <nav v-if="role == 'hcp'" class="nav nav-fill bottom-nav text-align text-center"> -->
  <!-- <router-link to="/doctor/index" class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/index') }" aria-current="page" ><font-awesome-icon :icon="['fas', 'home']" /><p class="bottom-title">Home</p></router-link> -->
  <!-- <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/consultationdesk') }" @click="getAccess('consultation')"><font-awesome-icon :icon="['fas', 'briefcase-medical']" /><p class="bottom-title">Consultation</p></div> -->
  <!-- <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/report') }" @click="getAccess('report')" ><font-awesome-icon :icon="['fas', 'file-signature']" /><p class="bottom-title">Reports</p></div> -->
  <!-- <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/library') }" @click="getAccess('library')" ><font-awesome-icon :icon="['fas', 'book-medical']" /><p class="bottom-title">Library</p></div>  -->
  <!-- <div class="nav-link bottom-link footer-image-aftersignup" :class="{ 'tab-activated': isRouteActive('/doctor/invite') }" @click="getAccess('invite')"><font-awesome-icon :icon="['fas','user-plus']" /><p class="bottom-title">Invite</p></div> -->
<!-- </nav> -->
  <!-- end doctor -->
  <nav v-if="role == 'admin'" class="nav nav-fill bottom-nav">
  <router-link to="/admin/index" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','home']" /><p class="bottom-title">Home</p></router-link>
  <router-link to="/admin/taskmanager" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','stethoscope']" /><p class="bottom-title">Task</p></router-link>
  <router-link to="/admin/financemanager" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">Finance</p></router-link>
  <router-link to="/admin/assetmanager" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">Assets</p></router-link>
   <router-link to="/admin/invite" class="nav-link bottom-link"><font-awesome-icon :icon="['fas','user-plus']" /><p class="bottom-title">Invite</p></router-link>
  </nav>
  <!-- end admin -->
   <!-- <nav v-else-if="role == 'user'" class="nav nav-fill bottom-nav"> -->
  <!-- <router-link to="/user/index" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/index') }" ><font-awesome-icon :icon="['fas','home']" /><p class="bottom-title">Home</p></router-link> -->
  <!-- <router-link to="/user/previewcard" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/previewcard') }" ><font-awesome-icon :icon="['fas', 'clinic-medical']" /><p class="bottom-title">Clinics</p></router-link> -->
  <!-- <router-link to="/user/patientgroups" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/patientgroups') }" ><font-awesome-icon :icon="['fas', 'user-group']" /><p class="bottom-title">Groups</p></router-link>               -->
  <!-- <router-link to="/user/publish" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','stethoscope']" /><p class="bottom-title">Publish</p></router-link> -->
  <!-- <router-link to="/user/records" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/records') }" ><font-awesome-icon :icon="['fas', 'file-medical']" /><p class="bottom-title">Records</p></router-link> -->
  <!-- <router-link to="" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/request') }" ><font-awesome-icon :icon="['fas', 'list']" /><p class="bottom-title">Requests</p></router-link> -->
  <!-- <router-link to="/user/library" class="nav-link bottom-link footer-image-aftersignup" aria-current="page" :class="{ 'tab-activated': isRouteActive('/user/library') }" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">Library</p></router-link> -->
  <!-- </nav>  -->
  <!-- end user -->
   <nav v-else class="d-none nav nav-fill bottom-nav">
  <router-link to="/index" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','home']" /><p class="bottom-title">Home</p></router-link>
  <router-link to="/doctors" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','stethoscope']" /><p class="bottom-title">Doctors</p></router-link>
  <router-link to="/user/request" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','stethoscope']" /><p class="bottom-title">Sign up / sign in</p></router-link>
  <router-link to="/aboutus" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">About us</p></router-link>
  <router-link to="/contactus" class="nav-link bottom-link" aria-current="page" ><font-awesome-icon :icon="['fas','book-medical']" /><p class="bottom-title">Contact us</p></router-link>
  </nav>
  <!-- end user -->
</div>
</template>
<script>
import axios from "axios";
export default {
    props: ["role"],
    data() {
    return {

    }},
    created: function () {
    // this.statusTrue();
  },
    methods: {
  currentPath(routePath) {
      this.$router.push(routePath);
    },
    isRouteActive(routePath) {
      return this.$route.path === routePath;
    },
    async getAccess(getvalues) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .get(`${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`)
        .then((response) => {
          if(response?.data){
             if (response.data?.status === null) {
              this.$swal("Please complete the About Me section of the Settings Page");
            }
            else if (response.data?.status?.status === "UnderReview") {
              this.$swal("Your request for access is under review. Please wait.");
            }
            else if(response.data?.status?.status === "Approved"){
              if(getvalues === 'consultation'){
                this.$router.push({ path: '/doctor/consultationdesk' });
              }
              else if(getvalues==='report'){
                this.$router.push({ path: '/doctor/report' });
              }
              else if(getvalues==='library'){
                this.$router.push({ path: '/doctor/library' });
              }
            }
            else if (response.data?.status?.status === "Rejected") {
              this.$swal("Please fix the review comments of your request for access");
            }


          }
  
        });
    },

    }
}
</script>
<style>
.footer-image-aftersignup{
  margin: 0px !important;
  color: #000 !important;
  cursor: pointer;
}
.tab-activated {
  background-color:#00979e;
  color: #fff !important;
  margin: 0px;
}
</style>
<template>
  <!-- Sidebar -->
  <!-- start admin -->
  <div
    class="border-right d-none d-lg-block" style="width: 400px;
    background: #c9c9c9"
    :class="{ show: active, 'd-block': active }"
  >
    <div v-if="role == 'admin'" class="sidenav">
      <router-link to="/admin/index">
        <div class="text-colorStyle">Home</div>
      </router-link>

      <router-link to="/admin/invite">
        <div class="text-colorStyle">Invite</div>
      </router-link>

      <router-link to="/admin/taskmanager">
        <div class="text-colorStyle">Task manager</div>
      </router-link>

      <router-link to="/admin/financemanager">
        <div class="text-colorStyle">Finance manager</div>
      </router-link>

      <router-link to="/admin/assetmanager">
        <div class="text-colorStyle">Assets manager</div>
      </router-link>
      <router-link to="/admin/clinicmanager">
        <div class="text-colorStyle">Clinic manager</div>
      </router-link>
      <router-link to="/admin/reports">
        <div class="text-colorStyle">Reports</div>
      </router-link>
      <router-link to="/admin/user-drop" v-if="this.adminIdObj !=3">
        <div class="text-colorStyle">Delete User</div>
      </router-link>
    </div>
    <!-- end admin -->

    <!-- start doctor -->
    <div @click="doctor()" v-if="role == 'hcp'" class="sidenavImg">
      <div   style="background-color: #bfd3d6">
        <div class="imgStyle">
          <img src="~@/assets/img/images/doctor.png" />
        </div>
      </div>
      <div class="doctorProfile">
        <b v-if="role == 'hcp'">Welcome {{ doctorPayload.firstName }}!</b>
      </div>
      <!-- <div class="doctorProfile">
        <a href="/doctor/groups">Add group</a>
      </div> -->
    </div>
    <div v-if="role == 'hcp'" class="sidenav">
      <div>
        <!-- <router-link to="/doctor/index" class="list-group-item-action text-decoration-none"> -->
        <div class="text-colorStyle" @click="messageOn()">Home</div>
        <!-- </router-link> -->
      </div>
      <!-- <div  :key="tabs" @click="getAccess('invite')" class="list-group-item-action text-decoration-none">
          <div class="text-colorStyle">Invite</div>
        </div> -->
      <div @click="getAccess('library')" class="list-group-item-action text-decoration-none" >
        <div class="text-colorStyle">Library</div>
      </div>
      <!-- <div to="#" @click="getAccess('')" class="list-group-item-action text-decoration-none">
          <div class="text-colorStyle">Message Desk</div>
        </div> -->
      <div @click="getAccess('consultationdesk')" class="list-group-item-action text-decoration-none" >
        <div class="text-colorStyle">Consultation desk</div>
      </div>

      <div @click="getAccess('report')" class="list-group-item-action text-decoration-none">
        <div class="text-colorStyle">Reports</div>
      </div>

      <router-link to="/doctor/doctorcommunities">
        <div class="text-colorStyle">Communities of doctors</div>
      </router-link>
      <router-link to="/doctor/patientsgroup">
        <div class="text-colorStyle">Patient support groups</div>
      </router-link> 
    </div>

    <!-- end doctor -->

    <!-- start user -->
    <div  @click="user()" v-if="role == 'user'" class="sidenavImg">
      <div  style="background-color: #bfd3d6">
        <div class="imgStyle">
          <img src="~@/assets/images/public-user.png" />
        </div>
      </div>
      <div  class="doctorProfile">
        <b v-if="role == 'user'">Welcome {{ usersPayload.firstName }}!</b>
      </div>
        <!-- <div class="doctorProfile">
          <a href="/user/groups">Add speciality</a>
        </div> -->
    </div>
    <div v-if="role == 'user'" class="sidenav d-none d-lg-block">
      <router-link to="/user/index">
        <div class="text-colorStyle">Home</div>
      </router-link>

      <!-- <router-link to="/user/publish" >
          <div class="text-colorStyle">Publish</div>
        </router-link>
      </div> 
        <router-link to="/user/request" >
          <div class="text-colorStyle">Requests</div>
        </router-link>
      </div>  -->
      <router-link
        to="/user/records"
        v-for="verifedMobile in AfterverifyMobile"
        :key="verifedMobile"
      >
        <div class="text-colorStyle">Medical records</div>
      </router-link>
      <div
        v-for="notVerifedMobile in BeforeverifyMobile"
        :key="notVerifedMobile"
        class="text-colorStyle"
        @click="MobileVerification()"
      >
        Medical Records
      </div>

      <router-link to="/user/library">
        <div class="text-colorStyle">Library</div>
      </router-link>
      <router-link to="/user/previewcard">
        <div class="text-colorStyle">Clinics using RxIx</div>
      </router-link>
      <router-link to="/user/doctorcommunities">
        <div class="text-colorStyle">Communities of doctors</div>
      </router-link>
      <router-link to="/user/patientgroups">
        <div class="text-colorStyle">Patient support groups</div>
      </router-link> 
    </div>
    <!-- end user -->
  </div>
  <!-- /#sidebar-wrapper -->
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
export default {
  props: ["role"],
  data() {
    return {
      clinicHome: false,
      offerConsult: false,
      clinicResource: false,
      postConsult: false,
      getCaseInput: false,
      settingsCheck: false,
      button: [],
      zero: [],
      rejected: [],
      pending: [],
      AfterverifyMobile: [],
      BeforeverifyMobile: [],
      doctorPayload: [],
      usersPayload: {},
      userRecords: [],
      publicId: {},
    };
  },
  methods: {
    async getDoctor() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .get(`${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`)
        .then((response) => {
          this.doctorPayload = response.data
        });
    },
    async getAccess(getvalues) {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios
        .get(`${BASE_API_URL}/hcps/${localStorage.getItem("id")}/one`)
        .then((response) => {
          if (response?.data) {
            if (response.data?.status === null) {
              Swal.fire({
              title: "Please complete the About Me section of the Settings Page",
              // icon:'question',
              confirmButtonColor: "#7066e0",
              confirmButtonText: "OK",
              }).then((result) => {
            if (result.isConfirmed) {
            const query = this.$route.query;
            this.$router.push({ path: `/doctor/settings`, query });
             }
            });
            } else if (response.data?.status?.status === "UnderReview") {
              this.$swal(
                "Your request for access is under review. Please wait."
              );
            } else if (response.data?.status?.status === "Approved") {
              if (getvalues === "library") {
                this.$router.push({ path: "/doctor/library" });
              } else if (getvalues === "consultationdesk") {
                this.$router.push({ path: "/doctor/consultationdesk" });
              } else if (getvalues === "report") {
                this.$router.push({ path: "/doctor/report" });
              }
            } else if (response.data?.status?.status === "Rejected") {
              this.$swal(
                "Please fix the review comments of your request for access"
              );
            }
          }
        });
    },

    UserStatus() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      axios;
      let publicUserId = localStorage.getItem("publicSignUpData");
      this.publicId = JSON.parse(publicUserId);
      axios
        .get(`${BASE_API_URL}/public-user/${this.publicId}/one`)
        .then((response) => {
          this.usersPayload = response.data;
          if (response.data?.mobile) {
            this.AfterverifyMobile.push(response.data);
          } else if (response.data?.mobile === null) {
            this.BeforeverifyMobile.push(response);
          }
        });
    },
    async user() {
      const query = this.$route.query;
      this.$router.push({ path: `/user/settings`, query });
    },

    async doctor() {
      const query = this.$route.query;
      this.$router.push({ path: `/doctor/settings`, query });
    },

    messageOn() {
      this.$router.push({ path: `/doctor/index` });
    },
    MobileVerification() {
      if (this.BeforeverifyMobile) {
        this.$swal(
          "Update the My info section of your profile to access this feature."
        );
      }
    },
  },
  created: function () {
    let adminID = localStorage.getItem("AdminsignIn");
     this.adminIdObj = JSON.parse(adminID);
    this.getDoctor();
    this.UserStatus();
    switch (this.$route.path.split("/")[3]) {
      case "postconsultation":
        this.postConsult = true;
        break;
      case "offerconsultation":
        this.offerConsult = true;
        break;
      case "accessclinical":
        this.clinicResource = true;
        break;
      case "receiveconsultation":
        this.clinicHome = true;
        break;
      case "getcase":
        this.getCaseInput = true;
        break;
      default:
        break;
    }
  },
};
</script>
<style>
.doctorProfile {
  text-align: center;
  font-size: 18px;
  cursor: pointer;
}

.imgStyle {
  width: 100%;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
}

.border-right {
  background-color: #f3f2ef;
}

.sidenav div.text-colorStyle {
  padding: 16px;
    font-size: 19px;
    color: #818181;
}

.sidenav div.text-colorStyle:hover {
  color: #34989f;
  cursor: pointer;
}

.sidenavImg {
  border-radius: 5px;
    background-color: #ffff;
    padding:5px;
    margin: 20px;
}

.sidenav {
  border-radius: 5px;
    background-color: #ffff;
    padding: 5px;
    margin: 20px;
}

.sidenav:hover {
  color: #111111;
}
</style>
